import React from 'react';
import { Router, Route, Switch, useRouteMatch, useParams } from 'react-router';
import AccessCodeGuard from 'pages/AccessCodeGuard';
import NotFound from 'pages/NotFound';
import CollectionLoader from 'pages/CollectionLoader';
import CollectionView from 'pages/CollectionView';
import VideoView from 'pages/VideoView';

export default function Routes({ history }) {
  return (
    <Router history={history}>
      <Switch>
        <Route path='/:collection/:code'>
          <AccessCodeRoutes />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
}

function AccessCodeRoutes() {
  const { collection, code } = useParams();
  return (
    <AccessCodeGuard collection={collection} code={code}>
      <CollectionRoutes collection={collection} />
    </AccessCodeGuard>
  );
}

function CollectionRoutes({ collection }) {
  const { path } = useRouteMatch();
  return (
    <CollectionLoader collection={collection}>
      <Switch>
        <Route exact path={path}>
          <CollectionView />
        </Route>
        <Route path={`${path}/:video`}>
          <CollectionVideoRoutes />
        </Route>
      </Switch>
    </CollectionLoader>
  );
}

function CollectionVideoRoutes() {
  const { video } = useParams();
  const { url: backUrl } = useRouteMatch('/:collection/:code');
  return (
    <VideoView video={video} backUrl={backUrl} />
  );
}
