import { createAction, handleActions } from 'redux-actions';
import { put, call, all, takeEvery, takeLatest, delay } from 'redux-saga/effects';
import dayjs from 'dayjs';
import { loginStart, loginComplete, logoutComplete, logout } from './login';
import * as authApi from 'api/auth';

export const tokenSet = createAction('token set', (token, expires_at, ephemeral) => ({ token, expires_at, ephemeral }));
export const tokenReset = createAction('token reset');

export const refreshTokenStart = createAction('refresh token start');
export const refreshTokenComplete = createAction('refresh token complete');

export const authReducer = handleActions({
  [tokenSet]: (state, action) => {
    const { token } = action.payload;
    return { ...state, token };
  },
  [tokenReset]: () => ({}),
}, {});

export function refreshToken() {
  return async (dispatch) => {
    dispatch(refreshTokenStart());
    try {
      const { token: newToken, expires_at, ephemeral } = await authApi.refreshTokenAsync();
      dispatch(refreshTokenComplete());
      dispatch(tokenSet(newToken, expires_at, ephemeral));
    } catch (err) {
      dispatch(refreshTokenComplete(err));
      dispatch(logout('expired'));
    }
  };
}

export function authReload() {
  return async (dispatch) => {
    const token = localStorage.getItem('token');
    const expires_at = localStorage.getItem('token_expiry');
    if (expires_at && dayjs(expires_at).isAfter(dayjs())) {
      dispatch(logout('expired'));
      return;
    }
    authApi.setAuthToken(token);
    if (token) {
      dispatch(loginStart('reload'));
      try {
        const res = await authApi.refreshTokenAsync();
        dispatch(loginComplete(res));
      } catch (err) {
        dispatch(loginComplete(err));
        dispatch(logout('expired'));
      }
    }
  };
}

function* loginBehaviour() {
  yield takeEvery(loginComplete.toString(), function* (action) {
    const { token, expires_at, ephemeral } = action.payload || {};
    yield put(tokenSet(token, expires_at, ephemeral));
  });

  yield takeEvery(logoutComplete.toString(), function* () {
    yield put(tokenReset());
  });
}

function* tokenRefreshBehaviour() {
  yield takeLatest([tokenSet.toString(), tokenReset.toString()], function* (action) {
    const { token, expires_at, ephemeral } = action.payload || {};

    authApi.setAuthToken(token);
    if (!token) {
      localStorage.removeItem('token');
      localStorage.removeItem('token_expiry');
      return;
    }

    if (!ephemeral) {
      localStorage.setItem('token', token);
      if (expires_at) localStorage.setItem('token_expiry', expires_at);
    }

    const now_dt = dayjs();
    const expiry_time = expires_at && dayjs(expires_at).diff(now_dt, 'ms');
    const refresh_dt = expiry_time && now_dt.add(expiry_time * 0.75, 'ms');

    while (true) {
      yield delay(60000);
      if (!refresh_dt || dayjs().isAfter(refresh_dt)) {
        yield put(refreshToken());
        break;
      }
    }
  });
}

export function* authSaga() {
  yield all([
    call(loginBehaviour),
    call(tokenRefreshBehaviour),
  ]);
}
