import React from 'react';
import { createBrowserHistory as createHistory } from 'history';
import { Provider } from 'react-redux';
import './App.scss';
import Routes from './routes';
import { createAppStore } from './store';

const history = createHistory();
const store = createAppStore(history);

export default function App() {
  return (
    <Provider store={store}>
      <Routes history={history} />
    </Provider>
  );
}
