import React from 'react';

export default function CollectionNavBar(props) {
  return (
    <nav className='navbar' role='navigation' aria-label='main navigation'>
      <div className='navbar-brand'>
        <a className='navbar-item' href='https://www.latindancehub.co.nz'>
          <img src='https://static.latindancehub.co.nz/images/dark_logo_transparent_websize.png' alt='Latin Dance Hub Logo' />
        </a>
        <div className='navbar-item'>
          <div className='content'>Latin Dance Hub Videos</div>
        </div>
      </div>
    </nav>
  );
}
