import { createStore, applyMiddleware, combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { all, call } from 'redux-saga/effects';
import { loginReducer } from './login';
import { authReducer, authSaga } from './auth';
import { collectionReducer } from './collection';

const loggerMiddleware = createLogger();
const sagaMiddleware = createSagaMiddleware();

const reducer = combineReducers({
  auth: authReducer,
  login: loginReducer,
  collection: collectionReducer,
});

function* rootSaga() {
  yield all([
    call(authSaga),
  ]);
}

/** @param {import('history').History} history */
export function createAppStore(/* history */) {
  const store = createStore(
    reducer,
    applyMiddleware(
      loggerMiddleware,
      thunkMiddleware,
      sagaMiddleware,
    )
  );

  sagaMiddleware.run(rootSaga);
  return store;
}
