import { handleActions, createAction } from 'redux-actions';
import * as authApi from 'api/auth';

export const loginPageLoad = createAction('login page load');
export const loginPageUnload = createAction('login page unload');

export const loginStart = createAction('login start', (method) => ({ method }));
export const loginComplete = createAction('login complete');
export const loginDismissError = createAction('login dismiss error');

export const logoutStart = createAction('logout start');
export const logoutComplete = createAction('logout complete', (reason) => ({ reason }));

export const loginReducer = handleActions({
  [loginPageLoad]: () => ({ pageloaded: true }),
  [loginPageUnload]: () => null,
  [loginStart]: (state) => ({
    ...state,
    pending: true,
  }),
  [loginComplete]: (state, action) => ({
    ...state,
    pending: false,
    ...(action.error ? { error: action.payload } : {}),
  }),
  [loginDismissError]: (state) => ({
    ...state,
    error: undefined,
  }),
  [logoutComplete]: (_state, action) => ({
    ...action.payload
  }),
}, null);

export function loginAccessCode({ collection, code }) {
  return async (dispatch) => {
    dispatch(loginStart('code'));
    try {
      const res = await authApi.loginAccessCodeAsync({ collection, code });
      const { token, expires_at, ephemeral } = res;
      dispatch(loginComplete({ token, expires_at, ephemeral }));
      return true;
    } catch (err) {
      dispatch(loginComplete(err));
    }
  };
}

export function logout(reason) {
  return async (dispatch) => {
    dispatch(logoutComplete(reason));
  };
}
