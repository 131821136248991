import React, { useEffect, useRef } from 'react';
import merge from 'deepmerge';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

/**
 * @param {{
 *  opts: import('video.js').VideoJsPlayerOptions
 * }} props 
 */
export default function VideoPlayer({ id, opts }) {
  const refVideo = useRef();
  const refPlayer = useRef();

  useEffect(() => {
    const finalOpts = merge({
      html5: {
        hls: {
          overrideNative: true,
        },
      },
    }, opts);
    const player = videojs(refVideo.current, finalOpts, () => {
      refPlayer.current = player;
    });
    return () => {
      player.dispose();
      refPlayer.current = undefined;
    };
  }, []);

  const VideoTag = 'video-js';
  return (
    <div data-vjs-player={true}>
      <VideoTag
        id={id}
        ref={refVideo}
        className='video-js vjs-default-skin'
      />
    </div>
  );
}
