import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginAccessCode } from 'store/login'
import AccessDenied from './AccessDenied';

export default function AccessCodeGuard({ collection, code, children }) {
  const dispatch = useDispatch();
  const [checking, setChecking] = useState(true);
  const { auth, login } = useSelector((state) => state);

  useEffect(() => {
    setChecking(true);
    (async () => {
      await dispatch(loginAccessCode({ collection, code }));
      setChecking(false);  
    })();
  }, [code, collection]);

  if (checking || (login && login.pending)) {
    return <div>loading ...</div>;
  } else if ((login && login.error) || !(auth && auth.token)) {
    return <AccessDenied />;
  } else {
    return React.Children.toArray(children);
  }
}