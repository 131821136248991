import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import join from 'join-url';
import CollectionNavBar from 'components/CollectionNavBar';

export default function CollectionView() {
  const {
    display,
    videos,
  } = useSelector((state) => state.collection || {});

  return (
    <>
      <CollectionNavBar />
      <section className='section'>
        <div className='container'>
          <h1 className='title'>{ display || 'Videos' }</h1>
          <div>
          {
            (videos || []).map((v, i) => (
              <div className='media' key={i}>
                <figure className='media-left'>
                  <Link to={
                    ({ pathname }) => ({
                      pathname: join.pathname(pathname, v.id),
                      state: { fromCollection: true },
                    })
                  }>
                    <p className='image is-64x64'>
                      <img src={v.thumb_url} />
                    </p>
                  </Link>
                </figure>
                <div className='media-content'>
                  <div className='content'>
                    <p>
                      <strong>{v.display}</strong><br />
                      {v.desc}
                    </p>
                  </div>
                </div>
              </div>
            ))
          }
          </div>
        </div>
      </section>
    </>
  );
}
