import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { collectionPageLoad, collectionPageUnload, loadCollection } from 'store/collection';

export default function CollectionLoader({ collection, children }) {
  const dispatch = useDispatch();
  const {
    pageloaded,
    pending,
    error,
  } = useSelector((state) => state.collection || {});

  useEffect(() => {
    dispatch(collectionPageLoad());
    dispatch(loadCollection({ collection }));
    return () => dispatch(collectionPageUnload());
  }, []);

  if (!pageloaded) return null;
  if (pending) return <div>Loading ...</div>;
  if (error) return <div>Error: { error.message || '' }</div>;

  return React.Children.toArray(children);
}
