import backend from './_backend';

export function setAuthToken(token) {
    backend.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

export async function loginAccessCodeAsync({ collection, code }) {
    const res = await backend.post('/auth/code', { collection, code });
    return res.data;
}

export async function refreshTokenAsync() {
    const res = await backend.post('/auth/refresh-token');
    return res.data;
}
