import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import NotFound from './NotFound';
import VideoPlayer from 'components/VideoPlayer';
import CollectionNavBar from 'components/CollectionNavBar';

export default function VideoView({ video, backUrl }) {
  const location = useLocation();
  const history = useHistory();
  const {
    videos,
  } = useSelector((state) => state.collection || {});

  const v = videos.find(({ id }) => id === video);
  if (!v) return <NotFound />;

  const { fromCollection } = location.state || {};
  const onBackHandler = () => {
    if (fromCollection) history.goBack();
    else history.push(backUrl);
  };

  return (
    <>
      <CollectionNavBar />
      <section className='section'>
        <div className='container'>
          <h1 className='title'>{ v.display }</h1>
          { v.desc ? <h2 className='subtitle'>{ v.desc }</h2> : null }
          <div className='field'>
            <div className='control'>
              <a className='button' onClick={onBackHandler}>
                &lt;&lt; Back to All Videos
              </a>
            </div>
          </div>
          <div className='content'>
            <VideoPlayer
              id='vid-full'
              opts={{
                controls: true,
                autoplay: false,
                preload: 'auto',
                responsive: true,
                fluid: true,
                sources: [
                  { src: v.hls_url, type: 'application/x-mpegURL', withCredentials: true },
                  { src: v.dash_url, type: 'application/dash+xml', withCredentials: true },
                ]
              }}
          />
          </div>
        </div>
      </section>
    </>
  );
}
