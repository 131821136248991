import { handleActions, createAction } from 'redux-actions';
import * as videosApi from 'api/videos';

export const collectionPageLoad = createAction('collection page load');
export const collectionPageUnload = createAction('collection page unload');

export const collectionLoadStart = createAction('collection load start', (collection) => ({ collection }));
export const collectionLoadComplete = createAction('collection load complete');

export const collectionReducer = handleActions({
  [collectionPageLoad]: () => ({ pageloaded: true }),
  [collectionPageUnload]: () => null,
  [collectionLoadStart]: (state) => ({ ...state, pending: true }),
  [collectionLoadComplete]: (state, action) => ({
    ...state,
    pending: false,
    ...(action.error
      ? { error: action.payload } 
      : action.payload
    ),
  }),
}, null);

export function loadCollection({ collection }) {
  return async (dispatch) => {
    dispatch(collectionLoadStart(collection));
    try {
      const res = await videosApi.getCollection(collection);
      const { id, display, videos } = res;
      dispatch(collectionLoadComplete({ id, display, videos }));
    } catch (err) {
      dispatch(collectionLoadComplete(err));
    }
  };
}
